import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseListService } from "../../../base/base-list.service";
import { StorageService } from 'app/main/module/common/service/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({ providedIn: 'root' })
export class CassetteListService {
  protected searchBy = 'barcode';
  baseUrl = environment.baseurl
  apiUrl = this.baseUrl + "/api/v2/"
  constructor(private userServiceChild: StorageService, private httpClient: HttpClient) {
    // super(afs1, userServiceChild);
    // this.table = 'cassette';
    // this.orderBy = 'dateTimeNum';
  }


  getSetsDetailByBarcode(barcode) {
    const token = this.userServiceChild.getCookie('token');
    const lab_id = this.userServiceChild.getCookie('lab_id');
    console.log("barcode= ", barcode)
    //   const headers = new HttpHeaders().set('Authorization', `${token}`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
    return this.httpClient.get(this.apiUrl + 'sets?lab_id=' + lab_id + '&barcode=' + barcode, httpOptions);
  }

  getSetsListByBarcodes(barcodeList,) {
    const token = this.userServiceChild.getCookie('token');
    const lab_id = this.userServiceChild.getCookie('lab_id');
    // console.log("barcode= ", barcodeList)
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization': token,
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Credentials': 'true'
    //   })
    // };
    // return this.httpClient.Post(this.apiUrl + 'sets?lab_id=' + lab_id, httpOptions);
    const headers = new HttpHeaders().set('Authorization', `${token}`);
    return this.httpClient.post(this.apiUrl + 'sets?lab_id=' + lab_id, barcodeList, { headers });

  }
 

  getAllCassetteData(page, attached) {

    const token = this.userServiceChild.getCookie('token');
    // console.log("call service ",token)
    const lab_id = this.userServiceChild.getCookie('lab_id');
    //   const headers = new HttpHeaders().set('Authorization', `${token}`);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
    return this.httpClient.get(this.apiUrl + 'sets?lab_id=' + lab_id + '&attached=' + attached + '&page=' + page, httpOptions);

  }

  getAllPatientCheckoutData(page) {

    const token = this.userServiceChild.getCookie('token');
    // console.log("call service ",token)
    const lab_id = this.userServiceChild.getCookie('lab_id');
    const status = "Assigned"
    const status2 = "Used"
    //   const headers = new HttpHeaders().set('Authorization', `${token}`); /api/v2/sets?per_page=1&page=1&lab_id=kirtilab11333&set_status=Ready
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
    return this.httpClient.get(this.apiUrl + 'sets?lab_id=' + lab_id + '&list_set_status=' + status +'&list_set_status='+status2 + '&page=' + page, httpOptions);

  }

  searchCasset(searchText) {

    const token = this.userServiceChild.getCookie('token');
    const lab_id = this.userServiceChild.getCookie('lab_id');
    // console.log("labid",lab_id)
    const page = 1;
    //  console.log("call service ",'searchText=' ,searchText)
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true'
      })
    };
    return this.httpClient.get(this.apiUrl + 'sets?lab_id=' + lab_id + '&barcode=' + searchText + '&attached=true', httpOptions);
  }


  
}
