import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Team } from './team';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../common/service/authentication.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { Globals } from '../../common/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../../common/service/api.service';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class TeamService implements Resolve<Team>{
    private itemsSubject: BehaviorSubject<Team[]>;
    public itemsObservable: Observable<Team[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    baseUrl = environment.baseurl
    // onDataChanged: BehaviorSubject<any>;
    apiUrl = this.baseUrl + '/api/v2/';
    // apiUrl = 'http://localhost:8080/api/v2/'
    apiUrlPython = this.baseUrl + '/api/v2/'
    ROWS = 100; // per page data
    orderBy = 'sortBy';
    table = 'team';
    currentPage = 0;
    private infoMsg: string;
    labName = '';
    data: any;
    allNames: Array<any> = [];
    // routeParams: any;

    labsObject = [];
    labsData = {
        labId: undefined,
        labName: undefined,
        role: 'NA',
        // hasLimit:false,
        // validTillDate: null

    };

    public notFoundSubject: BehaviorSubject<boolean>;
    public notFoundObservable: Observable<boolean>;
    userName: any;

    constructor(
        public authenticationService: AuthenticationService,
        private userService: StorageService,
        public globals: Globals,
        public apiService: ApiService,
        public httpClient: HttpClient,
    ) {

        // this.onDataChanged = new BehaviorSubject({});
    }


    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        //    return  this.apiService.getRoleAndPermission(this.userService.getCookie('lab')).then(returnData =>{
        //        return returnData;

        //    })
    }

    getAllTeammeberByLabId(labId) {
        const token = this.userService.getCookie('token');
        // // console.log("call service ",token)
        const headers = new HttpHeaders().set('Authorization', `${token}`);
        //    const email = this.userService.getCookie("email")
        //    // console.log("email===",email)
        return this.httpClient.get(this.apiUrl + 'users?lab_id=' + labId, { headers });

    }


    addTeammeberInLab(updatedata) {
        const token = this.userService.getCookie('token');
        //  // console.log("call service ",updatedata)
        const headers = new HttpHeaders().set('Authorization', `${token}`);
        //    const email = this.userService.getCookie("email")
        //    // console.log("email===",email)
        return this.httpClient.put(this.apiUrl + 'users', updatedata, { headers });

    }



    updateTeammeberData(updatedata) {
        const token = this.userService.getCookie('token');
        // console.log("call service ", updatedata)
        const headers = new HttpHeaders().set('Authorization', `${token}`);
        return this.httpClient.put(this.apiUrlPython + 'users', updatedata, { headers });

    }


    deleteTeammeberFromLab(emailId, labId) {
        const token = this.userService.getCookie('token');
        //  // console.log("call service ",id,labId);
        const headers = new HttpHeaders().set('Authorization', `${token}`);
        return this.httpClient.delete(this.apiUrlPython + 'users?email=' + emailId + '&lab_id=' + labId, { headers });

    }


}
