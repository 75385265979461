import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LabService } from '../lab.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { StorageService } from '../../../common/service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../common/global';
import { ApiService } from 'app/main/module/common/service/api.service';
import { U } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-labsetting',
    templateUrl: './labsetting.component.html',
    styleUrls: ['./labsetting.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LabsettingComponent implements OnInit {

    centresType: any;
    // labList: Array<string>;
    settingFormOne: FormGroup;
    settingFormTwo: FormGroup;
    settingFormThree: FormGroup;
    settingFormFour: FormGroup;
    currentLab = this.userService.getCookie('lab_id');
    selectedLab = this.userService.getCookie('lab_id');
    currentUser = this.userService.getCookie('email');
    currentTimeZone = this.userService.getCookie('defaultTz');
    timeZoneList = ['Asia/Kolkata', 'America/Toronto'];
    currentCenter = this.userService.getCookie('center');
    selectedCenter = this.userService.getCookie('center');
    role = this.userService.getCookie('role');
    currentRoleData: any;
    errorMessage: any;
    userLabsData: Array<any>;
    labsData: Array<any>;
    Labs: any;
    lablist: Array<any> = [];
    newlablist: Array<any> = [];
    memberInfo: any;
    memberId: any;
    ismultiCenter: string;
    centers: string;
    userId: any;
    totalLabs: any;
    uesrDocId: any;

    can_change_centre: any;
    can_change_lab: any;
    // can_select_as_role: any;
    allConfigurationByrole: Array<any> = [];
    existingUserLabs: Array<any> = [];
    labsDataIdsforSupport: Array<any> = [];
    existingUserLabIDs: Array<any> = [];
    changedCenter: any;
    labsDetail: any;
    multi_center: any;



    constructor(public labService: LabService,
        private apiService: ApiService,
        private formBuilder: FormBuilder, private userService: StorageService,
        private router: Router, public globals: Globals, private apiservice: ApiService) { }

    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.getROleconfiguration();
            this.getuserDatails();
            this.role = this.userService.getCookie("role")
            // // console.log('role= ' + this.role)
            this.getuserdatafromLabs().then(() => {
                if (this.role === 'support') {
                    this.getuserdatafromLabs().then(() => {
                        this.getLabArrayForExistRecordComaparission();
                        // console.log('support');
                    });
                } else {
                    this.getuserdatafromUser().then(() => {
                        this.getuserdatafromLabsForiscentertrue();
                        // console.log('user');
                    });
                }
            });

            this.settingFormOne = this.formBuilder.group({
                labListName: ['', Validators.required],
            });

            this.settingFormTwo = this.formBuilder.group({
                centerListName: ['', Validators.required],
            });

            this.settingFormThree = this.formBuilder.group({
                zoneListName: ['', Validators.required],
            });
        } else {
            this.logout();
        }

    }

    getROleconfiguration() {
        const loginUserRole = this.userService.getCookie('role');
        // // console.log("logdinuser role = ", loginUserRole)
        this.apiService.getAllRoleByName(loginUserRole).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.allConfigurationByrole = value;
                        this.allConfigurationByrole.forEach(element => {
                            this.currentRoleData = element.access_specifier;
                            for (const [key, value] of Object.entries(this.currentRoleData)) {
                                // // console.log(`${key}: ${value}`);
                                this.can_change_centre = this.currentRoleData.can_change_centre;
                                this.can_change_lab = this.currentRoleData.can_change_lab;

                            }
                        });
                        // // console.log('All Role config , can_change_centre = ', this.can_change_centre);
                    }
                    else {

                    }
                })
            },
            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );

    }

    async getuserdatafromLabs(): Promise<void> {
       return new Promise((resolve,reject) => {
        const lab_id = this.userService.getCookie("lab_id")
        this.centers = this.userService.getCookie('center');
        this.apiservice.getALlLabs().subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsData = value;
                        this.newlablist = [];
                        this.labsData.forEach(element => {
                            this.newlablist.push(element)
                            this.Labs = element.lab_id;
                            this.lablist.push(this.Labs)
                            if (lab_id === this.Labs) {
                                this.ismultiCenter = element.multi_center;
                                let uniqueArray = element.centers;
                                this.centresType = uniqueArray.filter((item, index) => {
                                    return uniqueArray.indexOf(item) === index;
                                });
                            }
                        });
                        this.lablist = this.lablist.filter((item, index) => {
                            return this.lablist.indexOf(item) === index;
                        });
                        resolve()
                    }
                    else {
                        resolve()
                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
                reject(err)
            }
        );
       })
    }


    async getuserdatafromLabsForiscentertrue(): Promise<void> {
        const lab_id = this.userService.getCookie("lab_id")
        this.centers = this.userService.getCookie('center');
        // // console.log("centers", this.centers)

        this.apiservice.getALlLabs().subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        const data1 = value;
                        data1.forEach(element => {
                            // // console.log('labs=', element._id)
                            if (lab_id === element.lab_id) {
                                this.ismultiCenter = element.multi_center;
                                // console.log("ismultiCenter = ", this.ismultiCenter)
                                let uniqueArray = element.centers;
                                this.centresType = uniqueArray.filter((item, index) => {
                                    return uniqueArray.indexOf(item) === index;
                                });
                            }
                        });
                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

            }

        );
    }

    async getLabArrayForExistRecordComaparission(): Promise<void> {
        this.uesrDocId = '';
        const token = this.userService.getCookie('token');
        const email = this.userService.getCookie("email")
        this.apiservice.getAllLabID(token, email).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDataIdsforSupport = value;
                        // this.existingUserLabs = value;
                        this.labsDataIdsforSupport.forEach(element => {
                            this.existingUserLabs = element.labs;
                            console.log('all config=', element.labs)
                        });
                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },
            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );
    }

    async getuserdatafromUser(): Promise<void> {
       return new Promise((resolve,reject) => {
        this.uesrDocId = '';
        const token = this.userService.getCookie('token');
        const email = this.userService.getCookie("email")
        this.apiservice.getAllLabID(token, email).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.userLabsData = value;
                        this.userLabsData.forEach(element => {
                            // // console.log('labs=', element._id, element.labs.length, element.labs)
                            this.totalLabs = element.labs;
                            this.uesrDocId = element._id
                            this.existingUserLabs = element.labs;




                        });
                        this.newlablist = [];
                        // this.lablist = this.totalLabs.map(obj => {
                        this.labsData.forEach((obj, index) => {
                            this.totalLabs.forEach(elementLab => {
                                if (obj.lab_id == elementLab.lab_id) {
                                    this.newlablist.push(obj)
                                    // console.log("lablist=", this.newlablist[0])

                                }

                            });
                        });
                        resolve()
                    }
                    else {
                        resolve()
                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
                reject(err)
            }

        );
       })
    }

    centerOnChangeVal(centerId) {
        // console.log(centerId);
        this.currentCenter = centerId;
    }

    labOnChangeVal(labId) {
        // // console.log(labId);
        this.currentLab = labId;
    }

    zoneOnChangeVal(event) {
        // // console.log(event);

    }
    updateLab() {
        const role = this.userService.getCookie('role');
        if (this.selectedLab === this.settingFormOne.value.labListName) {
            alert('Please change current lab to update.');
            return false;
        }


        const SelectlabId = this.settingFormOne.value.labListName;
        this.getTimeZone(SelectlabId)

    }

    getTimeZone(labId) {
        const token = this.userService.getCookie('token');
        this.apiService.getLabIdDetail(token, labId).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDetail = value;
                        this.labsDetail.forEach(element => {
                            console.log("multi_center=", element.multi_center)
                            this.multi_center = element.multi_center;

                            let defaultrole;
                            if (this.existingUserLabs.length > 0) {
                                this.existingUserLabs.map(obj => {
                                    const lab_id = obj.lab_id;
                                    const existDefaultLabRole = obj.role;
                                    if (this.multi_center == true) {
                                        this.changedCenter = obj.center;
                                    } else if (this.multi_center == false) {
                                        this.changedCenter = "";
                                    }

                                    // if (lab_id == labId) {
                                    defaultrole = existDefaultLabRole
                                    // } else {

                                    // }
                                });
                            }
                            const labData = {
                                email: this.userId,
                                update_data: {
                                    labs: [{ lab_id: labId, role: defaultrole, default_lab: true, center: this.changedCenter }],
                                    updated: ((-1) * new Date().getTime()).toString(),
                                    enabled: true,
                                }
                            }
                            console.log(labData)
                            // update lab in user collection with center blank, with default user role, default lab true
                            this.labService.updateUserLabDetail(labData).subscribe(data => {
                                Object.entries(data).find(([key, value]) => {
                                    if (key == 'status' && (value == 'success' || value == 'Success')) {
                                        const msg = `You have updated your lab with- ${labId}, please login again to proceed.`;
                                        alert(msg)
                                        this.logout()
                                    } else if (key == 'status' && value == 'failed') {
                                        const msg = 'Data Not updated , Somthing went wrong';
                                        // this.openSnackBar(msg, 'OK');
                                        alert(msg)
                                    }
                                })
                            })

                        });
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
                // this.isLoginFailed = true;

            }
        )

    }

    updateZone() {
        // // console.log(this.settingFormThree.value.zoneListName);
        const lab_id = this.userService.getCookie("lab_id")
        const timeZone: string = this.settingFormThree.value.zoneListName;
        // // console.log('timeZone = ' + timeZone);
        const labData = {
            lab_id: lab_id,
            update_data: {
                default_time_zone: timeZone
            }
        };
        this.labService.updateLab(labData).subscribe(data => {
            Object.entries(data).find(([key, value]) => {
                if (key == 'data') {
                    this.memberInfo = value;
                    this.memberInfo.forEach(element => {
                        this.memberId = element._id;
                        alert(`Time zone successfully updated.`);
                    });
                }
                else if (key == 'status' && value == 'failed') {
                    alert(`Time zone Not updated`);
                }
            })

        })
    }

    updateCenter() {
        // const lab_id = this.userService.getCookie("lab_id")
        if (this.selectedCenter === this.settingFormTwo.value.centerListName) {
            alert('Please change current center to update.');
            return false;
        }

        const centerId: string = this.settingFormTwo.value.centerListName;
        const lab_id = this.settingFormOne.value.labListName;
        // this.getTimeZone(lab_id);
        let defaultrole;
        let existlabId;
        if (this.existingUserLabs.length > 0) {
            this.existingUserLabs.map(obj => {
                // if(this.multi_center == true){
                //     this.changedCenter = obj.center;
                // }else if(this.multi_center == false){
                //     this.changedCenter = "";
                // }
                defaultrole = obj.role;
                existlabId = obj.lab_id
                this.changedCenter = obj.center;


            });
        }
        // console.log('if defaultrole= ' + defaultrole)
        const labData = {
            email: this.userId,
            update_data: {
                labs: [{ lab_id: lab_id, role: defaultrole, default_lab: true, center: centerId }],
                updated: ((-1) * new Date().getTime()).toString(),
                enabled: true,
            }
        }
        //update lab in user collection with center blank, with default user role, default lab true
        this.labService.updateUserLabDetail(labData).subscribe(data => {
            Object.entries(data).find(([key, value]) => {
                if (key == 'status' && (value == 'success' || value == 'Success')) {
                    const msg = `You have updated your center with- ${centerId}, please login again to proceed.`;
                    alert(msg)
                    this.logout()
                } else if (key == 'status' && value == 'failed') {
                    const msg = 'Data Not updated , Somthing went wrong';
                    // this.openSnackBar(msg, 'OK');
                    alert(msg)
                }
            })
        })
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();

    }

    getuserDatails() {
        const token = this.userService.getCookie('token');
        const email = this.userService.getCookie('email');

        this.apiService.getAllLabID(token, email).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.memberInfo = value;
                        this.memberInfo.forEach(element => {
                            // // console.log(element._id)
                            this.userId = element.email;
                        });
                    }
                    else if (key == 'status' && value == 'failed') {
                        alert('Email is not exist, Please contact your lab admin')

                    }
                })
            },
            err => {
                this.errorMessage = err.error.message;
                // // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            });
    }

}
